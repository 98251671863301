import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { useState, useEffect } from "react";
import { httpHelpers } from "../services/httpHelpers";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authServices from "../services/auth-services";
import { registerReferred } from "../actions/auth";
import { sendOtpToMail } from "../actions/sendOtp";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        LuckYo In
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AgentRegistrationPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [queryParameters] = useSearchParams();
  const [alert, setAlert] = useState(false);
  const api = httpHelpers();
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  // const [mail, setMail] = useState("");
  // const [otp, setOtp] = useState("");
  const [isdisabled, setEnabled] = useState(true);

  const [agentCode, setAgentCode] = useState(queryParameters.get("agentCode"));
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  // const onChangeOtp = (e) => {
  //   const otp = e.target.value;
  //   setOtp(otp);
  // };

  const onChangeUserName = (e) => {
    const username = e.target.value;
    setUserName(username);
    validate(username);
  };
  const validate = (usern) => {
    console.log("yes" + usern);
    let temp = { ...errors };
    usern = usern ? "" : "This field is required.";
    temp.userName = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(usern)
      ? ""
      : "Email is not valid.";

    console.log(temp.userName);
    setErrors({
      ...temp,
    });
    console.log(errors);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  // const onChangeMail = (e) => {
  //   const mail = e.target.value;
  //   setMail(mail);
  // };

  useEffect(() => {
    console.log(agentCode);
  }, [agentCode]);

  // const handleOtpSubmit = (e, text) => {
  //   console.log(e.target);
  //   console.log(text);
  //   if (mail === "") {
  //     setAlertMessage("Email is empty");
  //     setAlert(true);
  //     return;
  //   }

  //   sendOtpToMail(mail, userName)
  //     .then((data) => {
  //       console.log("send otp", data);
  //       if (data.status === 401) {
  //         setAlertMessage(data.data);
  //         setAlert(true);
  //       } else if (data.status === 200) {
  //         console.log("send otp properly");
  //         setAlertMessage("Otp sent to mail");
  //         setEnabled(false);
  //         setAlert(true);
  //       } else if (data.status === 500) {
  //         setAlertMessage("Internal Server Error");
  //         setAlert(true);
  //       } else if (data.status === 400) {
  //         setAlertMessage(data.data);
  //         setAlert(true);
  //       }
  //     })
  //     .catch(() => {});
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("first");

    if (agentCode === "") {
      setAlertMessage("Agent Code empty");
      setAlert(true);
      return;
    }
    dispatch(
      registerReferred(
        name,
        userName,
        agentCode,
        password,
        "refer/beta/fromAgent"
      )
    )
      .then((data) => {
        console.log("Register Data", data);
        if (data.status === 401) {
          setAlertMessage(data.data);
          setAlert(true);
        } else if (data.status === 200) {
          setAlertMessage("Valid Request");
          navigate("/registrationSuccess");
        } else if (data.status === 500) {
          setAlertMessage("Internal Server Error");
          setAlert(true);
        } else if (data.status === 400) {
          setAlertMessage(data.data);
          setAlert(true);
        }
      })
      .catch(() => {});
    console.log("Form submitted");
  };

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="MobileNo"
            label="Mobile"
            name="mobile"
            onChange={onChangeUserName}
            autoComplete="none"
            onBlur={onChangeUserName}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            onChange={onChangeName}
            label="Name"
            name="name"
            autoFocus
            autoComplete="off"
          />
          {/* <Grid container={true}>

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              onChange={onChangeMail}
              label="Email Address"
              autoComplete="off"
              name="email"
              inputProps={{
                autoComplete: 'off'
              }}
              autoFocus
            />
            <Button variant="contained" color="primary" onClick={(e) => handleOtpSubmit(e, "otp")}>Send OTP </Button>


          </Grid> */}
          {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="otp"
            onChange={onChangeOtp}
            label="OTP"
            name="otp"
            autoFocus
            autoComplete="off"
            disabled={isdisabled}

          /> */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            onChange={onChangePassword}
            type="password"
            id="password"
            autoSave="false"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={queryParameters.get("agentCode")}
            name="agentCode"
            label="agentCode"
            id="agentCode"
            disabled
          />

          {alert ? <Alert severity="error">{alertMessage}</Alert> : <></>}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs></Grid>
            <Grid item></Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

