import axios from "axios";
import TokenService from "./token-service";

const instance = axios.create({
  //baseURL: "http://11betmen.in/wingo/",
  //  baseURL: "http://192.168.29.161:8080/",
  // baseURL: "http://192.168.29.189:8080/",
  //baseURL: "http://192.168.1.9:8080/",
  //baseURL: "https://play.luckyo.in/backend/",
  baseURL: "https://vg.play-247.in/games",
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    console.log("config use", config);
    let role = "agent";
    if (config.url.includes("alpha")) {
      role = "admin";
    }
    const token = TokenService.getLocalAccessToken(role);
    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
      //config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    console.log("err.config", err.config);
    let role = "agent";
    let type = "beta";
    if (originalConfig.url.includes("alpha")) {
      role = "admin";
      type = "alpha";
    }
    console.log("res use", role, type);
    if (
      originalConfig.url !== "rest/v1/" + role + "/authenticate" &&
      err.response
    ) {
      // Access Token was expired
      console.log("err.response", err.response);
      console.log(
        "err.response.status",
        err.response.status,
        originalConfig._retry
      );
      console.log("err.config.headers", err.config.headers);
      const NO_RETRY_HEADER = "x-no-retry";
      if (
        err.response.status === 403 &&
        originalConfig.headers &&
        !originalConfig.headers[NO_RETRY_HEADER]
      ) {
        originalConfig.headers ||= {};
        originalConfig.headers[NO_RETRY_HEADER] = "true"; // string val only

        try {
          const rs = await instance.post(
            "rest/v1/" + role + "/generateRefreshToken",
            {
              refreshToken: TokenService.getLocalRefreshToken(role),
            }
          );

          const { jwt } = rs.data;
          TokenService.updateLocalAccessToken(jwt, role);

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;

